/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Button, Form, Result, Spin, Typography, notification } from 'antd'
import { useSearchParams } from 'react-router-dom'
import IncidentDetailsFormSection from './sections/details'
import IncidentPeopleFormSection from './sections/people'
import IncidentVehiclesFormSection from './sections/vehicles'
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks'
import StatusE from '../../../../../types/statusE'
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
  GTOB_BACKURL,
} from '../../../../../constants'
import getStyles from './styles'
import { mapFormValuesToIncidentType } from './utils'
import { IncidentT } from '../../../../../types/incidentT'
import { setStatus } from '../../../slice'
import useCallbackPrompt from './components/useCallbackPrompt'
import ConfirmDialog from './components/confirmDialog'
import { createDateFromString } from '../../../../../utils'
import { setBackurl, setKioskMode } from '../../../../login/slice'
import { createIncident } from '../../../actions'

export default function CreateIncidentForm() {
  const styles = getStyles()
  const [form] = Form.useForm<IncidentT>()
  const dispatch = useAppDispatch()
  const { status, error, response } = useAppSelector((state) => state.incident)
  const [formTouched, setFormTouched] = useState(false)
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
    status === StatusE.CREATED ? false : formTouched
  )
  const [searchParams] = useSearchParams()
  const kioskParam = searchParams.get('kiosk') || searchParams.get('amp;kiosk')
  const backurlParam = searchParams.get('backurl') || searchParams.get('amp;backurl')

  useEffect(() => {
    if (error) {
      notification.error({
        message: DEFAULT_ERROR_MESSAGE,
        description: (error.status ?? '').concat(` - ${error.message}`),
      })
    }
  }, [error])

  useEffect(() => {
    const people = Array.from(searchParams.values())
      .filter((value) => value.match(/{*}/))
      .map((value) => {
        const person = JSON.parse(value)
        return {
          ...person,
          birth_date: person.birth_date ? createDateFromString(person.birth_date) : '',
          type: 'offender',
        }
      })
    form.setFieldValue('people', people)
  }, [searchParams, form])

  useEffect(() => {
    dispatch(setKioskMode(kioskParam))
  }, [kioskParam])

  useEffect(() => {
    const backurl = localStorage.getItem(GTOB_BACKURL)
    if (!backurl) {
      localStorage.setItem(GTOB_BACKURL, backurlParam ?? '')
      dispatch(setBackurl(backurlParam))
    }
  }, [backurlParam])

  const onFinish = (values: IncidentT) => {
    const incident = mapFormValuesToIncidentType(values)
    window.scrollTo({ top: 0, behavior: 'smooth' })
    dispatch(createIncident(incident))
  }

  const onHandleNewIncident = () => {
    form.resetFields()
    dispatch(setStatus(StatusE.IDLE))
  }

  return status === StatusE.CREATED ? (
    <Result
      status="success"
      title={DEFAULT_SUCCESS_MESSAGE}
      subTitle={response?.message}
      extra={[
        <Button key="create" type="primary" onClick={onHandleNewIncident}>
          Create another incident
        </Button>,
      ]}
    />
  ) : (
    <Spin spinning={status === StatusE.LOADING}>
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        size="large"
        onFinish={onFinish}
        onFieldsChange={() => {
          setFormTouched(true)
        }}
      >
        <IncidentDetailsFormSection />
        <IncidentPeopleFormSection />
        <IncidentVehiclesFormSection />
        <Form.Item>
          <Typography.Paragraph
            type="secondary"
            style={styles.acknowledgeContent as React.CSSProperties}
          >
            A person that is marked as offender will be identified as a rogue guest to users of Good
            To Book, who will be advised against accepting future bookings from that person. Please
            ensure the data you provide is accurate.
          </Typography.Paragraph>
        </Form.Item>
        <Form.Item style={styles.submitButtonContainer}>
          <Button type="primary" htmlType="submit" loading={status === StatusE.LOADING}>
            Submit incident report
          </Button>
        </Form.Item>
      </Form>
      <ConfirmDialog
        showPrompt={showPrompt as boolean}
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
      />
    </Spin>
  )
}
